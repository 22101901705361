// export const objUrl = {
//     baseUrl: 'http://81.68.93.71:8060/api',
//     baseSignalrUrl: '116.62.138.103:8081',
//     baseImgUrl: 'http://81.68.93.71:8060',
//     fanyi:'http://47.74.88.194:8074/api'

// }

export const objUrl = {
    baseUrl: 'https://api.new-achievement.com/api',
    baseSignalrUrl: 'https://api.new-achievement.com',
    baseImgUrl: 'https://api.new-achievement.com',
    fanyi:'http://47.74.88.194:8074/api'

}
// export const objUrl={
// baseUrl:'http://47.74.88.194:8073/api',
//  baseSignalrUrl:'47.74.88.194:8081',
//  baseImgUrl:'http://47.74.88.194:8073' 
// }


// export const urlObj={想·
// baseUrl:'http://192.168.0.117:8073/api',
//  baseSignalrUrl:'192.168.0.117:8081' 
// }

// export const baseUrl = 'http://106.13.24.134:9091/mock/11/api'    //yapi服务器
export const loginMes = 'studyrooWebLoginMes';
export const version = '1.0.0';
export const deviceScanCode = ['anvenuspad', 'ed2b4e3a-2820-492f-9507-df165285e831', 'ed2b4e3b-2820-492f-9507-df165285e831', 'ed2b4e3c-2820-492f-9507-df165285e831'];
export default {
    objUrl,
    loginMes,
    version,
    deviceScanCode,
}