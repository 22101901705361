


















































import { Component, Emit, Vue } from "vue-property-decorator";
import topnavbar from "../components/topnavbar.vue";
import bottommenu from "../components/bottommenu.vue";
import beacome from "../components/beacome.vue";
@Component({
  components: {
    topnavbar,
    bottommenu,
    beacome,
  },
})
export default class starting extends Vue {
  isloginform: boolean = true;
  islogin: boolean = false;

  guanbilogin(data: boolean) {
    this.islogin = !this.islogin;
    this.isloginform = true;
  }
 
}
