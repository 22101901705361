import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
import { loginMes } from "../util/global";

export default new Vuex.Store({
  state: {
    userMes: {},
    token: "",
    language: "",
  },
  getters: {
    getUserMes(state) {
      if (!!state.userMes.token) {
        return state.userMes;
      } else {
        return Vue.$cookies.get(loginMes);
      }
    },
  },
  mutations: {
    clearUserMes(state) {
      state.userMes = {};
    },
    setChangeUserMes(state, data) {
      debugger
      let result = Vue.$cookies.get(loginMes);
      data.token = result.token;
      Vue.$cookies.set(loginMes, data);
      state.userMes = data;
    },
    setUserMes(state) {
      let result = Vue.$cookies.get(loginMes);

      state.userMes = result;
    },
  },
  actions: {},
  modules: {},
});
